microflow
  .controller("UICarouselCtrl", [
    "$scope",
    "$animate",
    "$interval",
    function($scope, $animate, $interval) {
      $scope.slide = [];
      $scope.direction = "next";
      $scope.activeIndex = 0;
      $scope.carousel = undefined;
      $scope.infinityLoop = false;
      $scope.hoverPause = false;
      $scope.intervalTime = 5000;
      $scope.currentInterval = null;
      $scope.isSliding = false;

      $scope.addSlide = function(element) {
        $scope.slide.push(element[0]);
      };

      $scope.activeClass = function() {
        $scope.isSliding = true;
        if (!$scope.$$phase) {
          //$digest or $apply
          $scope.$apply();
        }
        //@animation ดึก animation
        $animate.on("addClass", $scope.slide[$scope.activeIndex], function(
          element,
          phase
        ) {
          if (phase === "close") {
            $scope.isSliding = false;
            if (!$scope.$$phase) {
              //$digest or $apply
              $scope.$apply();
            }
            $animate.off("addClass", element);
          }
        });
        //@end animation
      };
      $scope.next = function() {
        var tempIndex = $scope.activeIndex;
        if (++$scope.activeIndex >= $scope.slide.length) {
          if ($scope.infinityLoop) {
            $scope.activeIndex = 0;
          } else {
            $scope.activeIndex = $scope.slide.length - 1;
          }
        }

        if (tempIndex !== $scope.activeIndex) {
          $scope.direction = "next";
          $scope.activeClass();
        }
      };
      $scope.prev = function() {
        var tempIndex = $scope.activeIndex;
        if (--$scope.activeIndex < 0) {
          if ($scope.infinityLoop) {
            $scope.activeIndex = $scope.slide.length - 1;
          } else {
            $scope.activeIndex = 0;
          }
        }
        if (tempIndex !== $scope.activeIndex) {
          $scope.direction = "prev";
          $scope.activeClass();
        }
      };

      $scope.slideTo = function(index) {
        if (index != $scope.activeIndex) {
          if (index > $scope.activeIndex) {
            $scope.direction = "next";
          } else {
            $scope.direction = "prev";
          }
          $scope.activeIndex = index;
          $scope.activeClass();
        }
      };

      $scope.restartTimer = function() {
        if (!$scope.currentInterval) {
          $scope.currentInterval = $interval(function() {
            if (!$scope.isSliding) {
              $scope.next();
            }
          }, parseInt($scope.intervalTime));
        }
      };

      $scope.resetTimer = function() {
        if ($scope.currentInterval) {
          $interval.cancel($scope.currentInterval);
          $scope.currentInterval = null;
        }
      };

      $scope.pause = function() {
        if ($scope.hoverPause) {
          $scope.resetTimer();
        }
      };

      $scope.play = function() {
        $scope.restartTimer();
      };
    }
  ])
  .directive("pgCarousel", [
    function(scope, element, attrs) {
      return {
        controller: "UICarouselCtrl",
        controllerAs: "crsCtrl",
        scope: true,
        restrict: "AEC",
        link: function(scope, el, attrs) {
          scope.carousel = el;
        }
      };
    }
  ])
  .directive("pgSlide", [
    "$animate",
    function($animate) {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.addSlide(element);
          scope.$watch("activeIndex", function(activeIndex) {
            /**@animation */
            var fn =
              scope.slide[activeIndex] === element[0]
                ? "addClass"
                : "removeClass";
            // trigger animation event listener
            $animate[fn](element, "active");
          });
        }
      };
    }
  ])
  .directive("pgSlideTo", [
    function() {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.$watch("activeIndex", function(activeIndex) {
            if (attrs.pgSlideTo == activeIndex) {
              element.addClass("active");
            } else {
              element.removeClass("active");
            }
          });
          scope.$watch("isSliding", function(isSliding) {
            if (scope.isSliding) {
              element.off("click");
            } else {
              element.on("click", function(event) {
                scope.slideTo(attrs.pgSlideTo);
              });
            }
          });
        }
      };
    }
  ])
  .directive("pgCarouselNext", [
    function() {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.$watch("isSliding", function(isSliding) {
            if (scope.isSliding) {
              element.off("click");
            } else {
              element.on("click", function(event) {
                scope.next();
              });
            }
          });
        }
      };
    }
  ])
  .directive("pgCarouselPrev", [
    function() {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.$watch("isSliding", function(isSliding) {
            if (scope.isSliding) {
              element.off("click");
            } else {
              element.on("click", function(event) {
                scope.prev();
              });
            }
          });
        }
      };
    }
  ])
  .directive("pgCarouselWrap", [
    function() {
      return {
        link: function(scope, element, attrs) {
          scope.infinityLoop = true;
        }
      };
    }
  ])
  .directive("pgCarouselAuto", [
    function() {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.restartTimer();
          element.on("mouseenter", scope.pause);
          element.on("mouseleave", scope.play);
        }
      };
    }
  ])
  .directive("pgCarouselInterval", [
    function() {
      return {
        require: "^pgCarousel",
        restrict: "A",
        link: function(scope, element, attrs) {
          scope.intervalTime = attrs.pgCarouselInterval;
        }
      };
    }
  ])
  .directive("pgCarouselHoverPause", [
    function() {
      return {
        link: function(scope, element, attrs) {
          scope.hoverPause = true;
        }
      };
    }
  ])
  .animation(".carousel-item", [
    "$animateCss",
    function($animateCss) {
      var classPrefix = "carousel-item-";
      function removeClass(element, className, callback) {
        element.removeClass(className);
        if (callback) {
          callback();
        }
      }
      return {
        beforeAddClass: function(element, className, doneFn) {
          if (className === "active") {
            var stopped = false;
            var scope = angular.element(element).scope();
            var direction = scope.direction;
            var directionClass =
              direction === "next"
                ? classPrefix + "left"
                : classPrefix + "right";
            var removeClassFn = removeClass.bind(
              this,
              element,
              [directionClass, classPrefix + direction].join(" "),
              doneFn
            );
            element.addClass(classPrefix + direction);
            $animateCss(element, { addClass: directionClass })
              .start()
              .done(removeClassFn);
            return function() {
              stopped = true;
            };
          }
          doneFn();
        },
        beforeRemoveClass: function(element, className, doneFn) {
          if (className === "active") {
            var stopped = false;
            var scope = angular.element(element).scope();
            var direction = scope.direction;
            var directionClass =
              direction === "next"
                ? classPrefix + "left"
                : classPrefix + "right";
            var removeClassFn = removeClass.bind(
              this,
              element,
              directionClass,
              doneFn
            );
            $animateCss(element, { addClass: directionClass })
              .start()
              .done(removeClassFn);
            return function() {
              stopped = true;
            };
          }
          doneFn();
        }
      };
    }
  ]);
